export class AccountCreationDTO {
    email: string | undefined;
    parentAccountId: number | undefined;
    accountGroupId: number | undefined;
    ageGroupId:number | undefined;
    regionId:number | undefined;
    serviceId:number | undefined;
    proxyId: number | undefined;
    loginUsername?: string;
    loginPassword?: string;
    recoveryEmail?: string;

    constructor(json:any) {
        this.email = json.email;
        this.regionId = json.regionId;
        this.serviceId = json.serviceId;
        this.ageGroupId =json.ageGroupId;
        this.parentAccountId=json.parentAccountId;
        this.accountGroupId=json.accountGroupId;
        this.proxyId = json.proxyId;
        this.loginUsername = json.loginUsername;
        this.loginPassword = json.loginPassword;
        this.recoveryEmail = json.recoveryEmail;
    }
}
