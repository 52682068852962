import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    CardActions,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {ProxyService} from "../../services/ProxyService";
import {useNavigate, useParams} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmationModal from "../../components/ConfirmationModal";
import {ProxyDTO} from "../../models/ProxyDTO";
import {Routes} from "../../router/Routes";
import {VisibilityEye} from "../../components/VisibilityEye";
import {RegionDTO} from "../../models/RegionDTO";
import {RegionService} from "../../services/RegionService";
import {ProxyCreationDTO} from "../../models/ProxyCreationDTO";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Controller, useForm} from "react-hook-form";
import {Clear} from "@mui/icons-material";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {SessionInstancesComponent} from "./SessionInstancesComponent";
import {ProxyStatsDTO} from "../../models/ProxyStatsDTO";
import ReportModal from "../../components/ReportModal";
import DownloadIcon from '@mui/icons-material/Download';
import {Dayjs} from "dayjs";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {Box} from "@mui/system";
import {GuardedComponent} from "../../components/GuardedComponent";

export function ProxyDetails() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openReportModal, setOpenReportModal] = useState<boolean>(false);
    const [regions, setRegions] = useState<RegionDTO[] | undefined>([]);
    const [proxyStats, setProxyStats] = useState<ProxyStatsDTO>();

    let {id} = useParams();

    function onSubmit() {
        return isAddMode ? addProxy() : editProxy()
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<ProxyCreationDTO>({
        defaultValues: {
            name: "",
            url: "",
            username: "",
            password: "",
            regionId: "",
            weight: 3,
        },
        mode: "onChange"
    });
    watch();

    const isAddMode = !id;
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.proxies,  currentlyOpened: false, href:Routes.PROXIES },
        {label: isAddMode ? strings.addProxy : strings.editProxyButton, currentlyOpened:true}
      ]

    const navigate = useNavigate();

    async function deleteProxy(): Promise<void> {
        setOpenModal(false);

        if (proxyData === null) {
            return;
        }

        try {
            await ProxyService.deleteProxy(proxyData.id);
            showSuccessDialog(strings.success, strings.proxyDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.PROXIES);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

    function getProxyStats(id: string | undefined) {
        if(id !== undefined) {
            ProxyService.getProxyStats(+id).then((data) => {
                setProxyStats(data);
            })
        }
    }

    useEffect(() => {
        getProxyStats(id);
    }, [id])

    function addProxy() {
        const proxy = new ProxyCreationDTO(getValues("name"),
            getValues("url"),
            getValues("username"),
            getValues("password"),
            getValues("regionId"),
            getValues("weight"),
            getValues("proxyGroupId")
        );

        ProxyService.createProxy(proxy).then(() => {
            showSuccessDialog(strings.success, strings.proxyAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.PROXIES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    const [proxyData, setProxyData] = useState<ProxyDTO | null>(null);

    useEffect(() => {
        async function getAllRegions() {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }

        function getProxyById(id: number) {
            ProxyService.getProxyById(id).then((res) => {
                setProxyData(res);
                setValue("name", res.name ?? "");
                setValue("regionId", res.region?.id?.toString() ?? "");
                setValue("username", res?.username ?? "");
                setValue("password", res?.password ?? "");
                setValue("url", res.url ?? "");
                setValue("weight", res.weight ?? "");
            })
        }

        async function getData() {
            await getAllRegions();

            if (id) {
                await getProxyById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id, setValue])

    function handleCancel(): void {
        navigate(Routes.PROXIES, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleReportModalOpen(): any {
        setOpenReportModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    function handleReportModalClose(): any {
        setOpenReportModal(false);
    }

    function editProxy() {
        const proxy = new ProxyCreationDTO(
            getValues("name"),
            getValues("url"),
            getValues("username"),
            getValues("password"),
            getValues("regionId"),
            getValues("weight"),
            getValues("proxyGroupId")
        );

        if (proxyData === null) {
            return;
        }

        ProxyService.editProxy(proxy, proxyData?.id).then(() => {
            showSuccessDialog(strings.success, strings.proxyUpdatedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.PROXIES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function downloadProxyReport(id: number, dateFrom: Dayjs | null, dateTo: Dayjs | null) {
        await ProxyService.downloadProxyReport(id, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo)).catch((error) => {
            const message = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {
            });
        });
    }

    async function downloadProxyAccountsReport(): Promise<void> {
        if (proxyData) {
            await ProxyService.downloadProxyAccountsReport(proxyData.id).catch((error) => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {
                });
            });
        }
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addProxy : strings.proxies}
        breadcrumbs={listLinks}
                     component={
            <>
                <CenteredCardLayout>
                             <form onSubmit={handleSubmit(onSubmit)}>
                                 <Controller
                                     name="name"
                                     rules={{required: true}}
                                     control={control}
                                     render={({field}) => (
                                         <TextField
                                             InputLabelProps={{
                                                 shrink: !!getValues("name"),
                                             }}
                                             {...field}
                                             value={field.value}
                                             id={"name"}
                                             label={strings.labelProxyName}
                                             className={"w-100"}
                                             style={{marginBottom: 15}}
                                             error={errors.name !== undefined}
                                         />
                                     )}
                                 />
                                 <Controller
                                     name="url"
                                     rules={{required: true}}
                                     control={control}
                                     render={({field: {onChange, value}}) => (
                                         <TextField
                                             InputLabelProps={{
                                                 shrink: !!getValues("url"),
                                             }}
                                             className={"w-100"}
                                             label={strings.labelProxyURL}
                                             style={{marginBottom: 15}}
                                             value={value}
                                             error={errors.url !== undefined}
                                             onChange={onChange}
                                         />
                                     )}
                                 />

                                 <Controller
                                     name="username"
                                     control={control}
                                     render={({field}) => (
                                         <TextField
                                             {...field}
                                             InputLabelProps={{
                                                 shrink: !!getValues("username"),
                                             }}
                                             className="mb-3"
                                             label={strings.username}
                                             type="text"
                                             variant="outlined"
                                             fullWidth
                                         />
                                     )}
                                 />

                                 <Controller
                                     name="password"
                                     rules={{required: false}}
                                     control={control}
                                     render={({field: {onChange, value}}) => (
                                         <VisibilityEye
                                             getValue={!!getValues("password")}
                                             value={value}
                                             className={"w-100"}
                                             required={false}
                                             onChange={onChange}
                                             label={strings.password}
                                         />
                                     )}
                                 />

                                 <FormControl className={"w-100"}>
                                     <InputLabel id="regionLabel">{strings.region}</InputLabel>
                                     <Controller
                                         name="regionId"
                                         control={control}
                                         render={({field: {onChange, value}}) => (
                                             <Select
                                                 labelId="regionLabel"
                                                 id="regionLabel"
                                                 onChange={onChange}
                                                 style={{marginBottom: 5}}
                                                 label={strings.region}
                                                 value={value}
                                                 inputProps={{shrink: getValues("regionId")}}
                                                 endAdornment={
                                                     !!getValues("regionId") && (<IconButton
                                                             onClick={() => onChange("")}
                                                         >
                                                             <Clear/>
                                                         </IconButton>
                                                     )
                                                 }
                                             >
                                                 {regions?.map((r) => (
                                                     <MenuItem key={r.id} value={r.id.toString()}>
                                                         {r.label}
                                                     </MenuItem>
                                                 ))}
                                             </Select>
                                         )}
                                     />
                                 </FormControl>
                                 <Controller
                                     name="weight"
                                     rules={{required: true}}
                                     control={control}
                                     render={({field}) => (
                                         <TextField
                                             {...field}
                                             value={field.value}
                                             InputLabelProps={{
                                                 shrink: !!getValues("weight"),
                                             }}
                                             type="number"
                                             onKeyPress={(event) => {
                                                 if (!/[0-9]/.test(event.key)) {
                                                     event.preventDefault();
                                                 }
                                             }}
                                             label={strings.weight}
                                             className={"w-100"}
                                             style={{marginBottom: 15, marginTop: 10}}
                                             error={errors.weight !== undefined}
                                         />
                                     )}
                                 />

                                 {!isAddMode ? (
                                     <>
                                         <Typography color="text.secondary">
                                             {strings.hasAuth + ":"}
                                             {proxyData?.hasAuth ? (
                                                 <CheckCircleIcon color={"primary"}/>
                                             ) : (
                                                 <CancelIcon color={"error"}/>
                                             )}
                                         </Typography>
                                         <Typography color="text.secondary">
                                             {strings.dateCreated + ": "}
                                             {DateTimeUtils.formatDate(proxyData?.dateCreated)}
                                         </Typography>
                                         <Typography color="text.secondary">
                                             {strings.dateModified + ": "}
                                             {DateTimeUtils.formatDate(proxyData?.dateModified)}
                                         </Typography>
                                         <Typography color="text.secondary">
                                             {strings.totalExecutedBotSessions} {proxyStats?.totalSessionInstances}
                                         </Typography>
                                         <Typography color="text.secondary">
                                             {strings.totalUpTime} {DateTimeUtils.convertSectoDay(proxyStats?.totalUpTimeInSeconds)}
                                         </Typography>
                                         <Typography className={"mt-1"} >
                                             <Button onClick={handleReportModalOpen}  className={"cursor-pointer btn btn-sm mb-2"} variant="contained">{strings.downloadProxyListeningStatsReport}<DownloadIcon style={{marginBottom: "2px",whiteSpace:"nowrap"}}/></Button>
                                         </Typography>

                                         <Typography className={"mt-1"} >
                                             <Button onClick={downloadProxyAccountsReport}  className={"cursor-pointer btn btn-sm mb-2"} variant="contained">{strings.downloadProxyAccountsReport}<DownloadIcon style={{marginBottom: "2px",whiteSpace:"nowrap"}}/></Button>
                                         </Typography>
                                     </>
                                 ) : null }
                                 <CardActions className="d-flex p-0 mt-4">
                                     {isAddMode ? <Button
                                         variant="contained"
                                         onClick={handleCancel}
                                         color={"warning"}
                                         className="w-50"
                                     >
                                         {strings.cancel}
                                     </Button> : <Button
                                         variant="contained"
                                         startIcon={<DeleteIcon/>}
                                         onClick={handleClickOpen}
                                         color={"error"}
                                         className="w-50"
                                     >
                                         {strings.deleteProxyButton}
                                     </Button>
                                     }
                                     <Button
                                         variant="contained"
                                         type="submit"
                                         startIcon={<EditIcon/>}
                                         color={"primary"}
                                         className="w-50"
                                     >
                                         Save Changes
                                     </Button>
                                     <ConfirmationModal
                                         open={openModal}
                                         handleClose={handleClose}
                                         handleConfirm={deleteProxy}
                                         title={strings.deleteProxy}
                                         content={strings.deleteProxyDialog}
                                         actionButtonName={strings.deleteProxyButton}
                                     />
                                     <ReportModal
                                         open={openReportModal}
                                         handleClose={handleReportModalClose}
                                         handleConfirm={(dateFrom, dateTo) => downloadProxyReport(proxyData!.id, dateFrom, dateTo)}
                                         title={strings.getReportForProxy + proxyData?.name}
                                         content={strings.report}
                                         actionButtonName={strings.downloadReport}/>
                                 </CardActions>
                             </form>
                </CenteredCardLayout>
                {!isAddMode &&
                  <Grid>
                      <GuardedComponent component={
                    <SessionInstancesComponent proxyId={proxyData?.id}/>
                      } guards={[
                      new PrivilegeGuard(
                          [Privileges.READ_SESSION_INSTANCES, Privileges.READ_REGIONS, Privileges.READ_SERVICES, Privileges.READ_AGE_GROUPS],
                          PrivilegeGuardMode.hasAll,
                      )]
                  } fallback={
                      <Box/>
                  } />
                  </Grid>
                }

            </>
                     }/>
    )
}
