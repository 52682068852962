import {RegionDTO} from "./RegionDTO";
import {ProxyUpdateDTO} from "./ProxyUpdateDTO";
import {ProxyGroupReducedDTO} from "./ProxyGroupReducedDTO";
export class ProxyDTO  {
    name: string;
    id: number;
    dateCreated: number;
    dateModified: number;
    dateDeleted?: string;
    url: string;
    username: string | null;
    password: string | null;
    region?: RegionDTO | null;
    // regionId = this.region?.id;
    // proxyId: number;
    weight: number;
    connectedAccounts: number;
    proxyGroup?: any;
    
    constructor(json: any) {
        this.name = json.name;
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.dateDeleted = json.dateDeleted;
        this.url = json.url;
        this.username = json.username;
        this.password = json.password;
        this.region =  json.region ? new RegionDTO(json.region) : undefined; 
        // this.regionId = json.regionId;
        // this.proxyId = json.proxyId;
        this.weight = json.weight;
        this.connectedAccounts = json.connectedAccounts;
        this.proxyGroup = json.proxyGroup ? new ProxyGroupReducedDTO(json.proxyGroup) : undefined;
    }
    
    get hasAuth(): boolean {
        return this.username !== null;
    }

    get isNotDeleted(): boolean {
        return this.dateDeleted === undefined;
    }

    toProxyUpdateDTO(): ProxyUpdateDTO {
        return new ProxyUpdateDTO({
            id: this.id,
            name: this.name,
            url: this.url,
            username: this.username,
            password: this.password,
            regionId: this.region?.id,
            weight: this.weight,
            proxyGroup: this.proxyGroup
        });
    }
}
