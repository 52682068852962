import {DateTimeUtils} from "../utils/DateTimeUtils";

export class AccountGroupScheduleDTO {
    id: number;
    dateCreated: Date;
    dateModified: Date;
    accountGroupId: number;
    startTime: Date;

    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.accountGroupId = json.accountGroupId;
        this.startTime = DateTimeUtils.parseTimeWithOffset(json.startTime);
    }
}
