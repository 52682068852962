export class Privileges {
    static readonly READ_SESSION_INSTANCES = "READ_SESSION_INSTANCES";
    static readonly MANAGE_SESSION = "MANAGE_SESSION";
    static readonly READ_SESSIONS = "READ_SESSIONS";
    static readonly READ_BOTS = "READ_BOTS";
    static readonly READ_SHARED_ACTIONS = "READ_SHARED_ACTIONS";
    static readonly READ_ACCOUNT_GROUPS = "READ_ACCOUNT_GROUPS";
    static readonly READ_ACCOUNTS = "READ_ACCOUNTS";
    static readonly MANAGE_ACCOUNT_GROUP = "MANAGE_ACCOUNT_GROUP";
    static readonly READ_SERVICES = "READ_SERVICES";
    static readonly READ_REGIONS = "READ_REGIONS";
    static readonly READ_AGE_GROUPS = "READ_AGE_GROUPS";
    static readonly MANAGE_ACCOUNT = "MANAGE_ACCOUNT";
    static readonly READ_PROXIES = "READ_PROXIES";
    static readonly READ_LISTENED_TACKS = "READ_LISTENED_TRACKS";
    static readonly READ_USERS = "READ_USERS";
    static readonly READ_CLIENTS = "READ_CLIENTS";
    static readonly CREATE_USER = "CREATE_USER";
    static readonly ADMINISTER_USER = "ADMINISTER_USER";
    static readonly READ_ROLES = "READ_ROLES";
    static readonly UPDATE_USER = "UPDATE_USER";
    static readonly DELETE_USER = "DELETE_USER";
    static readonly MANAGE_CLIENTS = "MANAGE_CLIENTS";
    static readonly MANAGE_PROXY = "MANAGE_PROXY";
    static readonly MANAGE_REGIONS = "MANAGE_REGIONS";
    static readonly READ_CLIENT_REPORT = "READ_CLIENT_REPORT"
    static readonly READ_CLIENT_REPORT_FOR_CLIENT = "READ_CLIENT_REPORT_FOR_CLIENT"
    static readonly DOWNLOAD_CLIENT_REPORT = "DOWNLOAD_CLIENT_REPORT";
    static readonly DOWNLOAD_CLIENT_REPORT_FOR_CLIENT = "DOWNLOAD_CLIENT_REPORT_FOR_CLIENT"
    static readonly READ_PHONES = "READ_PHONES"
    static readonly READ_MOBILE_PHONE_STATUSES = "READ_MOBILE_PHONE_STATUSES"
    static readonly MANAGE_MOBILE_PHONES = "MANAGE_MOBILE_PHONES"
    static readonly READ_MOBILE_PROXIES = "READ_MOBILE_PROXIES"
    static readonly MANAGE_SESSION_INSTANCE = "MANAGE_SESSION_INSTANCE"
    static readonly QUEUES = "QUEUES"
    static readonly READ_QUEUES = "READ_QUEUES"
    static readonly MANAGE_QUEUES = "MANAGE_QUEUES"
    static readonly READ_TARGET_PLAYLISTS = "READ_TARGET_PLAYLISTS"
    static readonly MANAGE_TARGET_PLAYLISTS = "MANAGE_TARGET_PLAYLISTS"
    static readonly MANAGE_TARGET_PLAYLIST_ITEM = "MANAGE_TARGET_PLAYLIST_ITEM"
    static readonly MANAGE_GENRES = "MANAGE_GENRES"
    static readonly READ_GENRES = "READ_GENRES"
}
