export class TargetPlaylistCreationDTO {
    key: string;
    service: string;
    name: string;
    description?: string;
    weight?: number;

    constructor(json: {
        key: string | undefined;
        service: string | undefined;
        name: string | undefined;
        description?: string;
        weight?: number;
    }) {

        this.key = json.key ?? '';
        this.service = json.service ?? '';
        this.name = json.name ?? '';
        this.description = json.description;
        this.weight = json.weight;
    }
}
