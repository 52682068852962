import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { strings } from "../../localization/Localization";
import { ListenedTrackDTO } from "../../models/ListenedTrackDTO";
import { SessionInstanceService } from "../../services/SessionInstanceService";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import { Collapse, TextField, Typography } from "@mui/material";
import { Cancel, CheckCircle, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Dayjs } from "dayjs";
import { NullableDatePicker } from "../../components/NullableDatePicker";
import { TableHeader } from "../../components/TableHeader";
import { SessionInstanceActionDTO } from "../../models/SessionInstanceActionDTO";
import { BooleanParser } from "../../utils/BooleanParser";
import {ActionTypeNomenclature} from "../../models/nomenclatures/ActionTypeNomenclature";
import {PaginationPlus} from "../../components/PaginationPlus";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {showErrorDialog} from "../../common/Dialogs";
import DownloadIcon from "@mui/icons-material/Download";
import {SessionInstance} from "../../models/SessionInstance";
export function ListenedTracks(props: { sessionInstance: SessionInstance; row: SessionInstanceActionDTO}) {
    const columnsTracks = [
        { label: strings.name, sortBy: "name", sortable: true },
        { label: strings.artist, sortBy: "artist", sortable: true },
        { label: strings.url, sortBy: "url", sortable: true },
        { label: strings.startDate, sortBy: "startDate", sortable: true },
        { label: strings.timeElapsedInSeconds, sortBy: "timeElapsedInSeconds", sortable: true },
    ]
    const { sessionInstance, row } = props;
    const indexId = props.row.id;
    const [searchParams, setSearchParams] = useSearchParams();
    const open = BooleanParser.parseBooleanNullable(searchParams.get(`open.${indexId}`));
    const [listenedTracks, setListenedTracks] = useState<ListenedTrackDTO[]>([]);
    const [totalElementsTracks, setTotalElementsTracks] = useState(0);
   
    const rowsPerPage = NumberParser.parseNumber(searchParams.get(`rowsPerPageTrack.${indexId}`), 25);
    const page = NumberParser.parseNumber(searchParams.get(`pageTrack.${indexId}`), 0);
    const listenedTrackId = searchParams.get("listenedTrackId") ?? "";
    const name = searchParams.get(`name.${indexId}`) ?? "";
    const artist = searchParams.get(`artist.${indexId}`) ?? "";
    const url = searchParams.get(`url.${indexId}`) ?? "";
    const startDate = searchParams.get(`startDate.${indexId}`) ?? null;
    const timeElapsedInSeconds = searchParams.get(`timeElapsedInSeconds.${indexId}`) ?? "";
    const sortingConfigurationString = searchParams.get(`sortTrack.${indexId}`);
    let { id } = useParams();
    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam(`pageTrack.${indexId}`, page.toString());
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam(`sortTrack.${indexId}`, sortingConfiguration.toSearchString());
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }
    function setOpen(value: boolean | undefined): void {
        setSearchParam(`open.${indexId}`, value?.toString() ?? "");
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam(`rowsPerPageTrack.${indexId}`, event.target.value);
        setPage(0);
    }
    function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam(`name.${indexId}`, event.target.value)
        setPage(0);
    }
    function handleChangeArtist(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam(`artist.${indexId}`, event.target.value)
        setPage(0);
    }
    function handleChangeUrl(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam(`url.${indexId}`, event.target.value)
        setPage(0);
    }
    function handleChangeTimeElapsed(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam(`timeElapsedInSeconds.${indexId}`, event.target.value)
        setPage(0);
    }
    function handleChangeStartDate(date: Dayjs | null) {
        setSearchParam(`startDate.${indexId}`, DateTimeUtils.formatDateDayJsWithSeconds(date));
        setPage(0);
    }

    async function downloadScreenshotsOfSessionInstanceAction(sessionInstanceId: number, sessionInstanceActionId: number) {
        await SessionInstanceService.downloadScreenshots(sessionInstanceId, sessionInstanceActionId).catch((error) => {
            const message = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        });
    }

    useEffect(()=>{
        async function getListenedTracksOfSessionInstance(page: number): Promise<void> {
            const response = await SessionInstanceService.getListenedTracksOfSessionInstance(page, rowsPerPage, id, listenedTrackId, indexId, name, url, startDate, timeElapsedInSeconds, artist, SortingConfiguration.fromSearchString(sortingConfigurationString));
            setTotalElementsTracks(response.totalElements);
            setListenedTracks(response.content)
        }
        getListenedTracksOfSessionInstance(page).then((_) => {
        });
    },[page, rowsPerPage, name, url, startDate, timeElapsedInSeconds, sortingConfigurationString, id, listenedTrackId, artist, indexId])
    return (
        <>
            <TableRow >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.order}</TableCell>
                <TableCell>{DateTimeUtils.formatDate(row.dateCreated)}</TableCell>
                <TableCell>
                    {
                        row.isPlaying && row.sessionAction.actionTypeData?.type===new ActionTypeNomenclature().SLEEP ?  row.sessionAction.actionTypeData?.label + " " + strings.listening :
                            !row.isPlaying && row.sessionAction.actionTypeData?.type===new ActionTypeNomenclature().SLEEP ?  row.sessionAction.actionTypeData?.label + " " + strings.pausing:
                                row.sessionAction.actionTypeData?.label
                    }
                </TableCell>
                <TableCell>{row.sourceKey}</TableCell>
                <TableCell>{row.durationInSeconds}</TableCell>
                <TableCell>{row.timeElapsedInSeconds}</TableCell>
                <TableCell>{DateTimeUtils.formatDate(row.dateFinished)}</TableCell>
                <TableCell>
                    {row.success ? (
                        <CheckCircle color={"primary"} />
                    ) : (
                        <Cancel color={"error"} />
                    )}
                </TableCell>
                <Tooltip title={row.errorMessage}><TableCell width={"20%"} className="truncate-table-cell">{row.errorMessage}</TableCell></Tooltip>
                <TableCell className="truncate-table-cell">{row.numberOfListenedTracks}</TableCell>
                <TableCell>
                    <Button className="btn btn-sm"
                            onClick={() => downloadScreenshotsOfSessionInstanceAction(sessionInstance.id, row.id)}
                            variant="contained"
                            style={{whiteSpace:"nowrap"}}>
                        <DownloadIcon/> {" "} {strings.downloadScreenshots}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {strings.listenedTracks}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                        <TextField
                                         variant="standard"
                                         value={name}
                                         label={strings.name}
                                         name="name"
                                         fullWidth
                                        onChange={handleChangeName}
                                       />
                                        </TableCell>
                                        <TableCell>
                                        <TextField
                                         variant="standard"
                                         value={artist}
                                         label={strings.artist}
                                         name="name"
                                         fullWidth
                                        onChange={handleChangeArtist}
                                       />
                                        </TableCell>
                                        <TableCell>
                                        <TextField
                                         variant="standard"
                                         value={url}
                                         label={strings.url}
                                         name="url"
                                         fullWidth
                                         onChange={handleChangeUrl}
                                       />
                                        </TableCell>
                                        <TableCell>
                                        <NullableDatePicker label={strings.dateCreated} value={startDate} onChange={handleChangeStartDate} />
                                        </TableCell>
                                        <TableCell>
                                        <TextField
                                         variant="standard"
                                         value={timeElapsedInSeconds}
                                         label={strings.timeElapsedInSeconds}
                                         name="timeElapsedInSeconds"
                                         fullWidth
                                        onChange={handleChangeTimeElapsed}
                                       />
                                        </TableCell>
                                    </TableRow>
                                    <TableHeader columns={columnsTracks} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                                </TableHead>
                                <TableBody>
                                    {listenedTracks.map((track: any) => (
                                        <TableRow key={track.id}>
                                            <TableCell>
                                                {track.name}
                                            </TableCell>
                                            <TableCell>
                                                {track.artist}
                                            </TableCell>
                                            <TableCell>
                                                {track.url}
                                            </TableCell>
                                            <TableCell>{DateTimeUtils.formatDate(track.startDate)}</TableCell>
                                            <TableCell>{track.timeElapsedInSeconds}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                                <PaginationPlus totalElements={totalElementsTracks} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                                <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElementsTracks}
                        rowsPerPage={rowsPerPage}
                        page={totalElementsTracks <= 0 ? 0 : page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
