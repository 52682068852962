export class ProxyCreationDTO {
    name: string | undefined;
    url: string | undefined;
    regionId: number | string | undefined;
    username: string | undefined;
    password: string | undefined;
    weight: number | undefined;
    proxyGroupId?: number | string | undefined;

    constructor(
        name: string | undefined,
        url: string | undefined,
        username: string | undefined,
        password: string | undefined,
        regionId: number | string | undefined,
        weight: number | undefined,
        proxyGroupId: number | string | undefined
    ) {
        this.name = name;
        this.url = url;
        this.regionId = regionId ? regionId : undefined;
        this.username = username ? username : undefined;
        this.password = password ? password : undefined;
        this.weight = weight !== undefined ? weight : undefined;
        this.proxyGroupId = proxyGroupId ? proxyGroupId : undefined;
    }

    static empty(): ProxyCreationDTO {
        return new ProxyCreationDTO(
            "",
            "",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
        );
    }
}
