import React, { useEffect, useState } from "react";
import { strings } from "../../localization/Localization";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { SideBarPage } from "../../components/SideBarPage"
import { AccountGroupService } from "../../services/AccountGroupService";
import { useParams } from "react-router-dom";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { AccountGroupScheduleDTO } from "../../models/AccountGroupScheduleDTO";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogTitle, Stack, TextField } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogs";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Edit } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleModal from "./ScheduleModal";
import AddIcon from "@mui/icons-material/Add";
import { AccountGroupScheduleCreationDTO } from "../../bot-session/dto/AccountGroupScheduleCreationDTO";
import { HeatMapGrid } from 'react-grid-heatmap'
import './AccountGroupsScheduleTable.css'
import {ScheduledInterval} from "../../models/ScheduledInterval";
import {BotConditionService} from "../../services/BotConditionService";
import {TableViewWithoutPagination} from "../../components/TableViewWithoutPagination";
import {Session} from "../../models/Session";

export function AccountGroupsScheduleTable() {
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(5);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [accounts, setAccountsTimeSchedule] = useState<AccountGroupScheduleDTO[]>([]);
    const [scheduledIntervals, setScheduledIntervals] = useState<ScheduledInterval[]>([])
    const [open, setOpen] = React.useState(false);
    const [timeValue, setTimeValue] = useState<Dayjs | null>();
    const [scheduleId, setScheduleId] = useState<number>()
    const [openModal, setOpenModal] = React.useState(false);
    const [isAddMode, setIsAddMode] = useState<Boolean>(false);
    const [sessionRows, setSessionRows] = useState<any>();
    const [numberOfNotDeadBots, setNumberOfNotDeadBots] = useState<number>(0);
    const xLabels = scheduledIntervals.map(i=>`${DateTimeUtils.convertSecToHours(i.startSecondOfDay) + "\r\n - \r\n" + DateTimeUtils.convertSecToHours(i.endSecondOfDay)}`)
    const yLabels = ['']

    function ConstructRowsAndShowTable(sessions:Session[]){
        const rows = sessions.map((element) => {
            return {
                id: element?.id,
                values: [
                    {
                        name: "sessionName",
                        value: element?.name
                    },
                    {
                        name: "accountGroupName",
                        value: element?.accountGroup?.name,
                    },
                    {
                        name: "numberOfSchedules",
                        value: element?.accountGroup?.schedules?.length,
                    },
                    {
                        name: "numberOfAccounts",
                        value: element?.accountGroup?.accountIds?.length,
                    }
                ]}
        })
        setSessionRows(rows)
    }
    const data = new Array(1)
        .fill(0)
        .map(() =>
            new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
        )

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(()=>{
            setIsAddMode(false);
        },200)
    };

    function handleClickOpenModal(): any {
        setOpenModal(true);
    }

    function handleCloseModal(): any {
        setOpenModal(false);
    }

    let { id } = useParams()

    useEffect(() => {
        let accountGroupId: number = 0;
        if (id) {
            accountGroupId = parseInt(id)
        }

        function getAccounts(page: number) {
            AccountGroupService.getScheduleByAccountGroupId(page, rowsPerPage, accountGroupId).then((data) => {
                setAccountsTimeSchedule(data.content)
                setTotalElements(data.totalElements)
            })
        }
        function getScheduledIntervals() {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            AccountGroupService.getScheduledIntervals(timeZone).then((data) => {
                setScheduledIntervals(data)
            })
        }

        function getConditionCount() {
            BotConditionService.botCondition().then((data) => {
                setNumberOfNotDeadBots(data?.alive! + data?.idle!)
            })
        }

        getScheduledIntervals();
        getConditionCount();
        getAccounts(page);
    }, [page, rowsPerPage, id]);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    async function editScheduleTime() {

        let object: AccountGroupScheduleCreationDTO = {
            accountGroupId: 0,
            startTime: ""
        }

        let date: Date | undefined;

        if (timeValue !== undefined) {
            date = timeValue?.toDate()
        }

        let accGroupId;

        if (id) {
            accGroupId = parseInt(id)
        }

        if (date !== undefined && accGroupId !== undefined) {
            object = {
                accountGroupId: accGroupId,
                startTime: DateTimeUtils.formatTimeWithOffset(date)
            }
        }
        if (scheduleId !== undefined) {
            await AccountGroupService.editAccountGroupScheduleTime(scheduleId, object).then(() => {
                handleClose()
                showSuccessDialog(
                    strings.success,
                    strings.editedSuccessfully,
                    strings.ok
                )
                    .then((_) => { window.location.reload() })
                    .catch((error) => {
                        const errorMessage = ErrorHandler.parseErrorMessage(error);
                        showErrorDialog(strings.error, errorMessage, strings.ok).then(
                            (_) => { }
                        );
                    });
            });
        }
    }

    function deleteAccountGroupSchedule(id: number | undefined) {
        if (id !== undefined) {
            AccountGroupService.deleteAccountGroupScheduleTime(id)
                .then(() => {
                    handleCloseModal()
                    handleClose()
                    showSuccessDialog(
                        strings.success,
                        strings.successfullyDeletedUser,
                        strings.ok
                    )
                        .then((_) => { window.location.reload() })
                        .catch((error) => {
                            const errorMessage = ErrorHandler.parseErrorMessage(error);
                            showErrorDialog(strings.error, errorMessage, strings.ok).then(
                                (_) => { }
                            );
                        });
                });
        }
    }

    function accountGroupSchedule() {
        let accGroupId;

        if (id) {
            accGroupId = parseInt(id)
        }

        let date: Date | undefined;

        if (timeValue !== undefined) {
            date = timeValue?.toDate();
        }

        let object: AccountGroupScheduleCreationDTO = { accountGroupId: 0, startTime: "" };

        if (date !== undefined && accGroupId !== undefined) {
            object = {
                accountGroupId: accGroupId,
                startTime: DateTimeUtils.formatTimeWithOffset(date)
            }
        }
        AccountGroupService.createAccountGroupScheduleTime(object).then(() => {
            handleClose()
            showSuccessDialog(
                strings.success,
                strings.successfullyAdded,
                strings.ok
            )
                .then((_) => { window.location.reload() })
                .catch((error) => {
                    const errorMessage = ErrorHandler.parseErrorMessage(error);
                    showErrorDialog(strings.error, errorMessage, strings.ok).then(
                        (_) => { }
                    );
                });
        });
    }

    useEffect(() => {
    }, [page, rowsPerPage, scheduleId])
    return (
        <SideBarPage
            pageTitle={strings.accountGroupsTitle}
            component={
                <Box>
                    <div>
                    </div>
                    <h4 style={{ textAlign: "center" }}>{strings.accountGroupScheduleTable}</h4>
                    <br />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '40%' }}>{strings.startTime}</TableCell>
                                    <TableCell sx={{ width: '20%' }}>{strings.dateCreated}</TableCell>
                                    <TableCell sx={{ width: '20%' }}>{strings.dateModified}</TableCell>
                                    <TableCell><Button
                                        variant="contained"
                                        className="btn btn-sm"
                                        onClick={() => {
                                            setIsAddMode(true)
                                            handleClickOpen()
                                            setTimeValue(dayjs())
                                        }}
                                    >
                                        {" "}
                                        <AddIcon /> {strings.schedule}
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map((e: any) => (
                                    <TableRow key={e.id}
                                        onClick={() => {
                                            handleClickOpen();
                                            setScheduleId(e.id);
                                            setTimeValue(e.startTime)
                                        }
                                        }
                                        hover={true}>
                                        <TableCell>{e.startTime !== null ? DateTimeUtils.formatTimeObject(e.startTime) : " "}</TableCell>
                                        <TableCell>{e.dateCreated !== null ? DateTimeUtils.formatDateObject(e.dateCreated) : " "} </TableCell>
                                        <TableCell>{e.dateModified !== null ? DateTimeUtils.formatDateObject(e.dateModified) : " "}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Dialog
                        open={open}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle textAlign={"center"} fontSize={"25px"}>{!isAddMode? strings.editSchedule : strings.schedule}
                        </DialogTitle>
                        <div className = "heatMapContainer">
                            <div>
                                <HeatMapGrid
                                    data={data}
                                    xLabels={xLabels}
                                    yLabels={yLabels}
                                    cellRender={(x, y, value) => (
                                        <div title={`Pos(${x}, ${y}) = ${scheduledIntervals[y].scheduledAccounts}`}>{scheduledIntervals[y].scheduledAccounts}</div>
                                    )}
                                    cellStyle={(x, y, ratio) => ({
                                        background: `rgb(255, 0, 0, ${numberOfNotDeadBots === 0 ? 1 : scheduledIntervals[y].scheduledAccounts/numberOfNotDeadBots  > 1 ? 1 : scheduledIntervals[y].scheduledAccounts/numberOfNotDeadBots})`,
                                        fontSize: '.8rem',
                                        color: `rgb(0, 0, 0, ${scheduledIntervals[y].scheduledAccounts / 2 + 0.4})`
                                    })}
                                    onClick={(x, y) => ConstructRowsAndShowTable(scheduledIntervals[y].sessions)}                                />
                            </div>
                            <br/>
                            <TableViewWithoutPagination columnNames={[strings.sessionName, strings.accountGroupName, strings.numberOfSchedules, strings.numberOfAccounts]} rows={sessionRows} emptyRowsMessage={strings.scheduledIntervalNoScenarios}/>

                        </div>
                        <br />
                        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3} width={"50%"}>
                                    <MobileTimePicker
                                        label="Start time"
                                        value={timeValue}
                                        onChange={((newValue) => {
                                            setTimeValue(newValue);
                                        })}
                                        renderInput={(params) => {
                                            return (<TextField {...params} />)
                                        }}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <br />
                            <div style={{ width: "10%" }}>
                            </div>
                            <br />
                            <div style={{ display: "flex", justifySelf: "space-around" }}>
                                {
                                    !isAddMode ?
                                        <>
                                            <div style={{marginRight:"4%"}}>
                                                <Button variant="contained" startIcon={<DeleteIcon />}
                                                    onClick={handleClickOpenModal} color={"error"} fullWidth
                                                    >
                                                    {strings.delete}
                                                </Button>
                                                <ScheduleModal open={openModal} handleClose={handleCloseModal}
                                                    handleDelete={() => deleteAccountGroupSchedule(scheduleId)} />
                                            </div>
                                            <Button
                                            type="submit"
                                            fullWidth
                                            style={{width:"170px"}}
                                            variant="contained"
                                            startIcon={<Edit />}
                                            onClick={() => editScheduleTime()}
                                        >
                                            {strings.saveChanges}
                                        </Button>
                                        </>
                                        :
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={() => accountGroupSchedule()}
                                    >
                                        {strings.save}
                                    </Button>
                                }
                            </div>
                        </DialogContent>
                    </Dialog>
                </Box>
            }
        />
    );
}
